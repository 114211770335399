import moment from 'moment'
import { DateError, DateFormat, DateFormatOutput, DateTimeFormat } from '@/interfaces/Date.interfaces'

/**
 * Takes an isoString and returns only the date part formatted as such : yyyy-mm-dd
 */
export const getDateFromISOString = (isoString: string): string => {
  const date = new Date(isoString)
  return moment(date).format(DateFormatOutput.ISO)
}

export const datetime = (value?: string): string | void => {
  if (value) {
    return moment(String(value)).format(DateFormatOutput.LLL)
  }
}

export const date = (value?: string): string | void => {
  if (value) {
    return moment(value, DateFormat.FR).format(DateFormatOutput.L)
  }
}

export const dateLarge = (value?: string): string | void => {
  if (value) {
    return moment(value, DateFormat.FR).format(DateFormatOutput.LL)
  }
}

export const datetimeSmall = (value?: string): string => {
  if (value) {
    return moment(value, DateTimeFormat.FR).local().format(DateFormatOutput.L_LT)
  }
  return DateError.CARET
}

export const localDatetime = (value?: string): string => {
  if (value) {
    return moment.utc(value, DateTimeFormat.FR).local().format(DateFormatOutput.L_LT)
  }
  return DateError.CARET
}

export const localDatetimeDate = (value?: string): string => {
  if (value) {
    return moment.utc(value, DateTimeFormat.FR).local().format(DateFormatOutput.L)
  }
  return DateError.CARET
}

export const localDatetimeLarge = (value?: string): string => {
  if (value) {
    return moment.utc(value, DateTimeFormat.FR).local().format(DateFormatOutput.LLL)
  }
  return DateError.CARET
}

export const dateFormat = (value?: string): string => {
  return moment.utc(value).local().format(DateFormatOutput.L_LT)
}

export const datetimeFormat = localDatetime

export const datetimeFormatUtc = (value?: string): string => {
  if (value) {
    return moment.utc(value, DateTimeFormat.FR).format(DateFormatOutput.L_LT)
  }
  return DateError.CARET
}

export const datetimeFormatNoHour = (value?: string): string => {
  if (value) {
    return moment.utc(value, DateFormat.FR).local().format(DateFormatOutput.L)
  }
  return DateError.CARET
}
