export enum DateFormat {
  ISO = 'YYYY-MM-DD',
  FR = 'DD/MM/YYYY',
}
export enum DateTimeFormat {
  FR = 'DD/MM/YYYY HH:mm:ss',
}
export enum DateError {
  INVALID = 'Invalid date',
  CARET = '-',
}

export enum DateFormatOutput {
  ISO = 'YYYY-MM-DD',
  /**
   * Month numeral, day of month, year : 09/04/1986
   */
  L = 'L',
  /**
   * Month name, day of month, year : September 4, 1986
   */
  LL = 'LL',
  /**
   * Month name, day of month, year, time : September 4, 1986 8:30 PM
   */
  LLL = 'LLL',
  /**
   * Month numeral, day of month, year + Time : 09/04/1986 8:30 PM
   */
  L_LT = 'L LT',
}
